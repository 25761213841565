import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { AxiosError } from "axios";

interface MercadoPagoPaymentData {
  token: string;
  transaction_amount: number;
  description: string;
  installments: number;
  payment_method_id: string;
  userId?: string;
  courseId?: string;
  payer: {
    email: string;
    identification: {
      type: string;
      number: string;
    };
  };
}

interface LoginData {
  email: string;
  password: string;
}

interface SignupData {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}

export default (axios: NuxtAxiosInstance, baseURL: string) => {
  const paymentUrl = `${baseURL}/process-payment`;

  return {
    processPayment: async (data: MercadoPagoPaymentData) => {
      try {
        if (
          !data.token ||
          !data.transaction_amount ||
          !data.payment_method_id
        ) {
          throw new Error("Faltan datos requeridos para procesar el pago");
        }

        const response = await axios.$post(paymentUrl, data);
        return response;
      } catch (error: any) {
        console.error("Error al procesar pago con MercadoPago:", error);
        throw error;
      }
    },

    login: async (data: LoginData) => {
      try {
        const response = await axios.post(`${baseURL}/auth/login`, data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    signup: async (data: SignupData) => {
      try {
        const response = await axios.post(`${baseURL}/auth/signup`, data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    checkUserExists: async (email: string) => {
      try {
        const response = await axios.get(`${baseURL}/users/${email}`);
        return response.data.exists;
      } catch (error: unknown) {
        if ((error as AxiosError)?.response?.status === 404) {
          return false;
        }
        throw error;
      }
    },
  };
};
