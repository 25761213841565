export function delay(ms) {
    return new Promise(resolve => {
        window.setTimeout(() => resolve(), ms);
    })
}

export function waitForCondition(conditionFn, timeout = 30000, interval = 100) {
    return new Promise(resolve => {
        var startTime = Date.now();
        const checkFlag = () => {
            if (conditionFn()) {
                resolve();
            } else if (timeout > 0 && Date.now() > startTime + timeout) {
                reject();
            } else {
                window.setTimeout(checkFlag, interval);
            }
        }
        checkFlag();
    });
}


export function extractFileName(path, ext = false) {
    let res = path.replace(/^.*[\\\/]/, '');
    if (!ext) res = res.replace(/\.[^/.]+$/, '');
    return res;
}

export function importAllDefaults(r, cache) {
    r.keys().forEach((key) => (cache[extractFileName(key)] = r(key).default));
}