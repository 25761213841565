import apiContact from "~/api/contact";
import apiCotizacion from "~/api/cotizacion";
import apiMercadoPago from "~/api/mercadopago";

export default (ctx, inject) => {
  const mercadoPagoApiURL = ctx.$config.mercadoPagoApiURL;

  if (!mercadoPagoApiURL) {
    console.error(
      "La URL de la API de MercadoPago no está configurada en las variables de entorno"
    );
  }

  const mercadoPagoApi = apiMercadoPago(ctx.$axios, mercadoPagoApiURL);

  inject("api", {
    contact: apiContact(ctx.$axios, ctx.$config.websiteContactApiURL),
    cotizacion: apiCotizacion(ctx.$axios, ctx.$config.cotizacionApiURL),
    mercadopago: mercadoPagoApi,
    auth: {
      login: mercadoPagoApi.login,
      signup: mercadoPagoApi.signup,
      checkUserExists: mercadoPagoApi.checkUserExists,
    },
  });
};
